.MenuItem.Mui-selected {
    background-color: #D8E9A8;
}

.MenuItem.Mui-selected:hover {
    background-color: #D9D9D9;
}

.MenuItem:hover {
    background-color: #D9D9D9;
}